import React from 'react';
import { useAppStore } from 'hooks/useAppStore';
import './Header.scss';
import { X } from 'react-feather';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';

interface Props {
  onClose: () => void;
  isWelcome: boolean;
  expanded: boolean;
  toggleExpand: (value: boolean) => void;
  displayWelcomeScreen: () => void;
}

const Header = ({ onClose, isWelcome, expanded, toggleExpand, displayWelcomeScreen }: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();

  return (
    <>
      <div className="d-flex align-items-center gap-4 header-assistant">
        <div
          onClick={displayWelcomeScreen}
          className="d-flex align-items-center"
        >
          <AIMascot
            faceSrc={happyFace}
            className={isWelcome ? 'ai-mascot' : 'ai-mascot ai-mascot-large'}
            isFloat={false}
          />
        </div>

        <div className="d-flex flex-column">
          {isWelcome ? (
            <>
              <div className="header-assistant__welcome">Hello, {fullName}</div>
              <div className="text-neutral-400">How can I help you?</div>
            </>
          ) : (
            <div className="header-assistant__welcome">Michi Assistant</div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-start gap-4 header-assistant__action-icon">
        {expanded ? (
          <ArrowsPointingInIcon
            className="cursor-pointer d-none d-lg-block"
            onClick={() => toggleExpand(false)}
          />
        ) : (
          <ArrowsPointingOutIcon
            className="cursor-pointer d-none d-lg-block"
            onClick={() => toggleExpand(true)}
          />
        )}
        <X
          className="close-icon gray-400 cursor-pointer"
          size={20}
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default Header;
