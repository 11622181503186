import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Trash2 } from 'react-feather';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useAppStore } from 'hooks/useAppStore';
import './ImportAndUpload.scss';
import { AnalyzeContentInputForm } from 'types';
import { useMutation } from 'react-query';
import { uploadFiles } from 'apis/AiChat/AiChat';
import { removeUUIDFromFilename } from 'utils';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { useAnalyzeContent } from 'hooks/ai-chat';
import AIMascot from '../AIMascot/AIMascot';
import AILoading from '../AILoading/AILoading';
import { Loading } from 'components/Loading';
import { countWords } from 'components/AIOffset/utils';
import { URL_REGEXP } from 'constants/regexp';

const ImportAndUpload = ({ chatId, analyzeSuccess }: { chatId: string; analyzeSuccess: () => void }) => {
  const {
    userInfo: { fullName },
  } = useAppStore();
  const { mutate: analyzeContent, isLoading: isAnalyzing, isSuccess } = useAnalyzeContent();

  const { register, handleSubmit, watch, setValue } = useForm<AnalyzeContentInputForm>({
    defaultValues: {
      fileUrls: [],
      webUrls: [],
      text: '',
    },
  });
  const inputUrlRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [fileError, setFileError] = useState<string | null>(null);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const [urlError, setUrlError] = useState<string | null>(null);

  const { mutate: uploadMutate } = useMutation((files: File[]) => uploadFiles(chatId, files), {
    onMutate: files => {
      setPendingFiles(prev => [...prev, ...files]);
    },
    onSuccess: data => {
      setValue('fileUrls', [...watch('fileUrls'), ...data]);
      setPendingFiles([]);
    },
    onError: () => {
      setPendingFiles([]);
    },
  });

  const supportedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
  ];

  const watchFiles = watch('fileUrls');
  const watchUrls = watch('webUrls');
  const MAX_FILE_UPLOAD = 5;
  const MAX_FILE_SIZE_MB = 10;
  const FILE_SIZE_10MB = MAX_FILE_SIZE_MB * 1024 * 1024; // 10MB

  const onSubmit = async (data: AnalyzeContentInputForm) => {
    if (data.fileUrls?.length > MAX_FILE_UPLOAD) {
      setFileError(`You can only upload up to ${MAX_FILE_UPLOAD} files`);
      return;
    }

    setFileError(null);
    setUrlError(null);

    analyzeContent({
      chatId,
      payload: {
        fileUrls: data.fileUrls,
        webUrls: data.webUrls,
        text: data.text,
      },
    });
  };

  const validateAndUploadFiles = (files: File[]) => {
    const validFiles = files.filter(file => {
      const isValidType = supportedFileTypes.some(type => file.type.startsWith(type));
      const isValidSize = file.size <= FILE_SIZE_10MB;
      return isValidType && isValidSize;
    });

    const errorMessage = getErrorMessage(files, validFiles);
    setFileError(errorMessage);

    if (validFiles.length > 0) {
      uploadMutate(validFiles);
    }
  };

  const getErrorMessage = (files: File[], validFiles: File[]): string | null => {
    if (files.length === 1 && files[0].size > FILE_SIZE_10MB) {
      return `File cannot exceed ${MAX_FILE_SIZE_MB}MB`;
    }
    if (validFiles.length !== files.length) {
      return `Some files were skipped due to unsupported type or size exceeding ${MAX_FILE_SIZE_MB}MB`;
    }
    return null;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      validateAndUploadFiles(files);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const removeFile = (index: number) => {
    const updatedFiles = watchFiles.filter((_, i) => i !== index);
    setValue('fileUrls', updatedFiles);
    setFileError(null);
  };

  const addUrl = () => {
    const url = inputUrlRef.current?.value;

    if (!url) {
      setUrlError(null);
      return;
    }

    if (URL_REGEXP.test(url)) {
      setValue('webUrls', [...watchUrls, url]);
      inputUrlRef.current.value = '';
      setUrlError(null);
    } else {
      setUrlError('URL is invalid');
    }
  };

  const removeUrl = (index: number) => {
    const updatedUrls = watchUrls.filter((_, i) => i !== index);
    setValue('webUrls', updatedUrls);
  };

  const handleUrlChange = () => {
    setUrlError(null);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const words = value.trim().split(/\s+/);

    if (words.length <= 5000) {
      setValue('text', value);
    } else {
      const truncatedValue = words.slice(0, 5000).join(' ');
      setValue('text', truncatedValue);
      e.target.value = truncatedValue;
    }
  };

  const handleTextareaInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('text');
    const combinedText = currentContent + (currentContent ? ' ' : '') + pastedText;
    const words = combinedText.trim().split(/\s+/);
    const truncatedValue = words.slice(0, 5000).join(' ');

    setValue('text', truncatedValue);
    (e.target as HTMLTextAreaElement).value = truncatedValue;

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const content = watch('text');
    const words = content.trim().split(/\s+/);
    if (words.length > 5000 && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const isSubmitDisabled = () => {
    const { fileUrls, webUrls, text } = watch();
    return fileUrls.length === 0 && webUrls.length === 0 && text.trim() === '';
  };

  useEffect(() => {
    if (isSuccess) {
      analyzeSuccess();
    }
  }, [isSuccess]);

  if (isAnalyzing)
    return (
      <div className="p-4">
        <AILoading />
      </div>
    );

  return (
    <div className="brainstorming-content d-flex flex-column gap-2 p-4">
      <>
        <div className="assistant">
          <div className="d-flex align-items-center py-1">
            <AIMascot
              className="ai-mascot ai-mascot-small"
              faceSrc={happyFace}
              isFloat={false}
            />
            <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
          </div>
          <div className="guide p-2 font-size-14 fw-normal text-neutral-900">
            Please begin by giving me some context! You can import files, share URL links, or paste the text of your
            content.
          </div>
        </div>
        <div className="human-chat">
          <div className="import-upload-wrapper">
            <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="import-upload-section d-flex flex-column p-2 bg-primary-50 gap-3"
            >
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div className="mb-1 font-size-14 fw-medium text-neutral-900">Import a file</div>
                      <div className="fonts-size-14 fw-normal text-neutral-500">
                        Upload documents from your local devices
                      </div>
                    </div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept={supportedFileTypes.join(', ')}
                      style={{ display: 'none' }}
                      id="fileInput"
                      multiple
                      ref={fileInputRef}
                    />
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={handleUploadClick}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <div className="text-neutral-400 fw-normal font-size-12">
                  Support files: PDF, DOC, DOCX, PPT, PPTX, and TXT files (up to 5 files, 10MB each)
                </div>
                {fileError && <div className="font-size-14 text-error-500">{fileError}</div>}
                {watchFiles.map((file, index) => (
                  <div
                    className="list-file"
                    key={`uploaded-${index}`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="file-name font-size-12 fw-semibold text-neutral-900">
                        {removeUUIDFromFilename(file.name)}
                      </div>
                      <Trash2
                        size={16}
                        className="trash-icon cursor-pointer flex-shrink-0"
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  </div>
                ))}
                {pendingFiles.map((file, index) => (
                  <div
                    className="list-file"
                    key={`pending-${index}`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <Loading size="sm" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex flex-column gap-2">
                <div>
                  <div className="mb-1 font-size-14 fw-medium text-neutral-900">Import from URL</div>
                  <div className="fonts-size-14 fw-normal text-neutral-500">
                    Webpage URLs should contain text, while video URLs should offer a transcript for Michi to process.
                  </div>
                </div>

                <div className="d-flex flex-row gap-2">
                  <div className="d-flex flex-fill align-items-center search-wrapper">
                    <input
                      ref={inputUrlRef}
                      className="search-input d-flex align-items-center h-100 flex-fill w-1px text-truncate"
                      type="text"
                      placeholder="Enter URL and click Add"
                      onChange={handleUrlChange}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={addUrl}
                  >
                    Add
                  </button>
                </div>
                {urlError && <div className="font-size-14 text-error-500">{urlError}</div>}
                {watchUrls.length > 0 &&
                  watchUrls.map((url, index) => (
                    <div
                      className="list-file"
                      key={index}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="file-name font-size-12 fw-semibold text-neutral-900 text-decoration-underline">
                          {url}
                        </div>
                        <Trash2
                          size={16}
                          className="trash-icon cursor-pointer flex-shrink-0"
                          onClick={() => removeUrl(index)}
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="font-size-14 fw-medium text-neutral-900">Import from text</div>
                  <div className="fonts-size-12 fw-normal text-neutral-400">{countWords(watch('text'))}/5000</div>
                </div>
                <textarea
                  {...register('text')}
                  className="area-input d-flex align-items-center flex-fill"
                  placeholder="Describe your learning material. You can either type it or paste it directly."
                  rows={1}
                  onChange={handleTextareaChange}
                  onPaste={handlePaste}
                  onKeyDown={handleKeyDown}
                  onInput={handleTextareaInput}
                  ref={textareaRef}
                />
              </div>
              <div className="p-2">
                <button
                  type="submit"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={isSubmitDisabled()}
                >
                  <SparklesIcon className="me-2" />
                  Analyse Content
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default ImportAndUpload;
