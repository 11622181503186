import React from 'react';
import './WelcomeSection.scss';
import thinkingFace from 'assets/images/ai-assistant/face-thinking.svg';
import workingFace from 'assets/images/ai-assistant/face-working.svg';
import { AiChatType } from 'types';
import AIMascot from '../AIMascot/AIMascot';

interface WelcomeSectionProps {
  changeViewMode: (mode: AiChatType) => void;
}

const WelcomeSection = ({ changeViewMode }: WelcomeSectionProps) => {
  return (
    <div className="welcome-section p-3 p-lg-4">
      <div
        className="option cursor-pointer mb-3"
        onClick={() => changeViewMode(AiChatType.BRAINSTORM)}
      >
        <div className="icon d-none d-lg-flex align-items-center justify-content-center flex-shrink-0 mb-2">
          <AIMascot
            faceSrc={thinkingFace}
            className="ai-mascot ai-mascot-large"
            isFloat={false}
          />
        </div>
        <div className="title font-size-24 fw-medium mb-3">
          Brainstorming your learning content with Michi&apos;s help
        </div>
        <div className="description font-size-14 fw-normal">
          Don&apos;t know where to start? Use Michi&apos;s insights to quickly brainstorm and enhance your learning
          content ideas.
        </div>
      </div>
      <div
        className="option cursor-pointer"
        onClick={() => changeViewMode(AiChatType.IMPORT)}
      >
        <div className="icon d-none d-lg-flex align-items-center justify-content-center flex-shrink-0 mb-2">
          <AIMascot
            faceSrc={workingFace}
            className="ai-mascot ai-mascot-large"
            isFloat={false}
          />
        </div>
        <div className="title font-size-24 fw-medium mb-3">Import and create your learning content within minutes</div>
        <div className="description font-size-14 fw-normal">
          Already has content to upload or insert? Effortlessly import and create engaging learning materials in just
          minutes with Michi&apos;s streamlined process.
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
