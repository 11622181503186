import { ContentType } from '@spiderbox/common';

export type AiChatQuery = {
  limit?: number;
  offset?: number;
};

export type AiChatHistory = {
  id: string;
  name: string;
  userPrompt: string;
  aiContext: string;
  importedDocuments: AnalyzeContentInputForm;
  chatType: AiChatType;
};

export type ChatDetailResponse = {
  aiChat: AiChatHistory;
  messages: AIMessage[];
};

export type AIMessage = {
  id: string;
  aiChatId: string;
  messageType: AIMessageType;
  messageContentType: AIMessageContentType;
  content: string;
  metadata: Metadata;
  actionType: AIMessageActionType;
};

export enum AIMessageType {
  Human = 'Human',
  AI = 'AI',
}

export enum AIMessageContentType {
  Text = 'Text',
  Multimedia = 'Multimedia',
  Link = 'Link',
  Context = 'Context',
  Content = 'Content',
}

export enum AIMessageActionType {
  Chat = 'Chat',
  GenerateContent = 'GenerateContent',
  RefineContext = 'RefineContext',
  RefineContent = 'RefineContent',
  System = 'System',
  GenerateContext = 'GenerateContext',
}

export type AnalyzeContentInputForm = {
  fileUrls?: UploadFileResponse[];
  webUrls?: string[];
  text?: string;
};

export type UploadFileResponse = {
  name: string;
  url: string;
  mediaId: string;
};

export type CreateAIMessagePayload = {
  content: string;
  actionType: AIMessageActionType;
  contentType?: ContentType;
};

export enum AiChatType {
  BRAINSTORM = 'Brainstorm',
  IMPORT = 'Import',
}

export type SelectContentTypeInputForm = {
  contentType: { label: string; value: ContentType };
  additionalInfo: string;
};

export type Metadata = {
  contentType?: ContentType;
  library?: string;
  userInput?: string;
  structuredContent?: any;
};
