import React, { useEffect, useRef } from 'react';
import './RefineModal.scss';
import { Edit2 } from 'react-feather';
import { useAppStore } from 'hooks/useAppStore';
import { useForm } from 'react-hook-form';
import { AIMessageActionType, AIMessageContentType, AIMessageType } from 'types';
import { addMessageToChatHistory, useCreateMessage } from 'hooks/ai-chat';
import { useQueryClient } from 'react-query';
import AILoading from '../AILoading/AILoading';
import { countWords } from 'components/AIOffset/utils';

type Props = {
  label: string;
  onClose: () => void;
  currentMessageContentType: AIMessageContentType;
  chatId: string;
  scrollBottom: () => void;
};

const RefineModal = ({ label, onClose, currentMessageContentType, chatId, scrollBottom }: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { register, watch, handleSubmit, setValue } = useForm<{ content: string }>({
    defaultValues: {
      content: '',
    },
  });

  const { mutateAsync, isLoading } = useCreateMessage();

  const handleActionType = (messageContentType: AIMessageContentType) => {
    if (messageContentType === AIMessageContentType.Context) {
      return AIMessageActionType.RefineContext;
    }

    return AIMessageActionType.RefineContent;
  };

  const refine = async (data: { content: string }) => {
    const actionType = handleActionType(currentMessageContentType);

    addMessageToChatHistory({
      queryClient,
      chatId,
      message: {
        content: data.content,
        messageType: AIMessageType.Human,
        actionType,
        messageContentType: currentMessageContentType,
      },
    });

    await mutateAsync({ chatId, content: data.content, actionType });

    onClose();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const words = value.trim().split(/\s+/);

    if (words.length <= 300) {
      setValue('content', value);
    } else {
      const truncatedValue = words.slice(0, 300).join(' ');
      setValue('content', truncatedValue);
      e.target.value = truncatedValue;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('content');
    const combinedText = currentContent + (currentContent ? ' ' : '') + pastedText;
    const words = combinedText.trim().split(/\s+/);
    const truncatedValue = words.slice(0, 300).join(' ');

    setValue('content', truncatedValue);
    (e.target as HTMLTextAreaElement).value = truncatedValue;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const content = watch('content');
    const words = content.trim().split(/\s+/);
    if (words.length > 300 && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isLoading) scrollBottom();
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="w-100 mt-3">
        <AILoading />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column w-100 align-items-end">
      <div className="refine-modal-wrapper">
        <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
        <form
          onSubmit={handleSubmit(refine)}
          className="refine-modal-section p-2"
        >
          <div className="font-size-14 fw-medium text-neutral-900 mb-1">{label}</div>
          <div className="d-flex align-items-center justify-content-between  mb-2">
            <div className="font-size-14 fw-normal text-neutral-500">
              Need further tweaks? Simply ask Michi for assistance
            </div>
            <div className="font-size-12 fw-normal text-neutral-500">{countWords(watch('content'))}/300</div>
          </div>

          <div className="d-flex flex-fill align-items-center area-wrapper mb-3">
            <textarea
              {...register('content')}
              ref={textareaRef}
              className="area-input d-flex align-items-center flex-fill"
              placeholder="Tell Michi what to do..."
              rows={5}
              onChange={handleTextareaChange}
              onPaste={handlePaste}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="d-flex p-2">
            <button
              className="btn btn-outline-primary d-flex align-items-center me-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary d-flex align-items-center"
              disabled={!watch('content')}
            >
              <Edit2 className="me-2" />
              Refine
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RefineModal;
