import React, { useEffect } from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { ContentType } from '@spiderbox/common';
import { addMessageToChatHistory, useCreateMessage } from 'hooks/ai-chat';
import { useAppStore } from 'hooks/useAppStore';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Select, { components, ControlProps, InputProps, OptionProps } from 'react-select';
import { AIMessageActionType, AIMessageContentType, AIMessageType, SelectContentTypeInputForm } from 'types';
import AILoading from '../AILoading/AILoading';
import './SelectContentModal.scss';

type Props = {
  onClose: () => void;
  chatId: string;
  scrollBottom: () => void;
};

const SelectContentModal = ({ onClose, chatId, scrollBottom }: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SelectContentTypeInputForm>({
    defaultValues: {
      contentType: null,
      additionalInfo: '',
    },
  });

  const { mutateAsync: generateContentMessage, isLoading: isGenerateLoading } = useCreateMessage();
  const additionalInfo = watch('additionalInfo');

  const options = Object.values(ContentType).map(contentType => ({
    label: contentType,
    value: contentType,
  }));

  const filterOptions = (candidate, input) => {
    if (!input) return true;
    const searchTerm = input.toLowerCase();
    return candidate.label.toLowerCase().includes(searchTerm.trim());
  };

  const generateContent = async (data: SelectContentTypeInputForm) => {
    addMessageToChatHistory({
      chatId,
      queryClient,
      message: {
        content: data.additionalInfo,
        messageType: AIMessageType.Human,
        actionType: AIMessageActionType.GenerateContent,
        messageContentType: AIMessageContentType.Text,
        metadata: {
          contentType: data.contentType.value,
        },
      },
    });

    await generateContentMessage({
      chatId,
      content: data.additionalInfo,
      actionType: AIMessageActionType.GenerateContent,
      contentType: data.contentType.value,
    });

    onClose();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 300);
    setValue('additionalInfo', value);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('additionalInfo');
    const newContent = (currentContent + pastedText).slice(0, 300);
    setValue('additionalInfo', newContent);
  };

  useEffect(() => {
    if (isGenerateLoading) scrollBottom();
  }, [isGenerateLoading]);

  if (isGenerateLoading) {
    return (
      <div className="w-100 mt-3">
        <AILoading />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column w-100 align-items-end">
      <div className="select-content-modal-wrapper">
        <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
        <form
          onSubmit={handleSubmit(generateContent)}
          className="select-content-modal-section p-2 d-flex flex-column gap-3"
        >
          <div className="d-flex flex-column gap-1">
            <div className="font-size-14 fw-medium text-neutral-900 mb-1">Select Content Type</div>
            <div className="font-size-12 fw-normal text-neutral-400">Re-select content type if needed.</div>
            <Controller
              name="contentType"
              control={control}
              rules={{ required: 'Please select a content type first' }}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={false}
                  options={options}
                  classNamePrefix="select"
                  placeholder="Choose a content type"
                  backspaceRemovesValue={false}
                  blurInputOnSelect={false}
                  filterOption={filterOptions}
                  isClearable
                  isSearchable
                  components={{
                    Control: ({ children, ...props }: ControlProps) => (
                      <components.Control
                        {...props}
                        className="px-3 py-2"
                      >
                        {children}
                        {props.menuIsOpen ? (
                          <ChevronUp
                            size={20}
                            className="text-neutral-900"
                          />
                        ) : (
                          <ChevronDown
                            size={20}
                            className="text-neutral-900"
                          />
                        )}
                      </components.Control>
                    ),
                    Option: (props: OptionProps) => {
                      const library = props.data as any;

                      return (
                        <components.Option {...props}>
                          <div className="d-flex align-items-center font-size-14 fw-normal text-neutral-900">
                            {library.label}
                          </div>
                        </components.Option>
                      );
                    },
                    Input: (props: InputProps) => {
                      return <components.Input {...props} />;
                    },
                  }}
                ></Select>
              )}
            />
            {errors.contentType && <div className="font-size-14 text-error-500 mt-1">{errors.contentType.message}</div>}
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="font-size-14 fw-medium text-neutral-900">Additional Information</div>
              <div className="font-size-12 fw-normal text-neutral-500">{additionalInfo.length}/300</div>
            </div>
            <div className="d-flex flex-fill align-items-center area-wrapper">
              <Controller
                name="additionalInfo"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className="area-input d-flex align-items-center flex-fill"
                    placeholder="Tell Michi what to do..."
                    rows={5}
                    onChange={handleTextareaChange}
                    onPaste={handlePaste}
                    maxLength={300}
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex p-2">
            <button
              className="btn btn-outline-primary d-flex align-items-center me-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary d-flex align-items-center"
              type="submit"
            >
              <SparklesIcon className="me-2" />
              Generate Content
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SelectContentModal;
