import classNames from 'classnames';
import React from 'react';
import './AppBadge.scss';

interface Props {
  type:
    | 'success'
    | 'warning'
    | 'error'
    | 'neutral'
    | 'success-outline'
    | 'warning-outline'
    | 'error-outline'
    | 'neutral-outline'
    | 'primary'
    | 'primary-light'
    | 'neutral-outline-hover';
  label: string | JSX.Element;
  dataCy?: string;
}

export const AppBadge: React.FC<Props> = ({ type = 'success', label = '', dataCy = 'app-badge' }) => {
  return (
    <div className="d-flex">
      <div
        className={classNames('app-badge d-flex align-items-center justify-content-center', type)}
        data-cy={dataCy}
      >
        {label}
      </div>
    </div>
  );
};
