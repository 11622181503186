import React from 'react';
import './AILoading.scss';
import AIMascot from '../AIMascot/AIMascot';
import speed from 'assets/images/ai-assistant/speed.svg';
import runningFace from 'assets/images/ai-assistant/face-running.svg';

const AILoading = () => {
  return (
    <div className="ai-loading d-flex flex-column align-items-center justify-content-center gap-4 p-2">
      <AIMascot
        className="ai-mascot"
        faceSrc={runningFace}
        runningSrc={speed}
        isFloat={true}
      />
      <div className="d-flex flex-column gap-2 font-size-18 text-center">
        <div className="working fw-semibold">I&apos;m working on it!</div>
        <div className="fw-normal text-neutral-400">This usually takes less than 1 minute.</div>
      </div>
    </div>
  );
};

export default AILoading;
