import React from 'react';
import './UserUploadPrompt.scss';
import { ExternalLink } from 'react-feather';
import { useAppStore } from 'hooks/useAppStore';
import { AnalyzeContentInputForm } from 'types';
import { removeUUIDFromFilename } from 'utils';

interface Props {
  chatId: string;
  importedDocuments: AnalyzeContentInputForm;
}

const UserUploadPrompt = ({ importedDocuments }: Props) => {
  const {
    userInfo: { fullName },
  } = useAppStore();

  const { fileUrls, webUrls, text } = importedDocuments;

  const handleExternalLinkClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="user-upload-wrapper">
      <div className="d-flex justify-content-end font-size-12 fw-semibold text-neutral-600 mb-1">{fullName}</div>
      <div className="user-upload-prompt d-flex flex-column gap-3 p-3 bg-primary-50">
        <div className="text-neutral-900">
          <div className="mb-2 font-size-14 fw-medium">Import files</div>
          <div className="d-flex flex-column gap-2">
            {fileUrls &&
              fileUrls.map((file, index) => (
                <div key={index}>
                  <div className="list-file">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="file-name font-size-12 fw-semibold text-decoration-underline cursor-pointer">
                        {removeUUIDFromFilename(file.name)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {webUrls &&
          webUrls.map((url, index) => (
            <div
              className="text-neutral-900"
              key={index}
            >
              <div className="mb-2 font-size-14 fw-medium">Import from URL</div>
              <div className="list-file">
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="file-name font-size-12 fw-semibold text-decoration-underline cursor-pointer"
                    onClick={() => handleExternalLinkClick(url)}
                  >
                    {url}
                  </div>
                  <ExternalLink
                    size={16}
                    className="icon cursor-pointer flex-shrink-0"
                    onClick={() => handleExternalLinkClick(url)}
                  />
                </div>
              </div>
            </div>
          ))}

        {text && (
          <div className="font-size-14 text-neutral-900">
            <div className="mb-2 fw-medium">Import from text</div>
            <div className="fw-normal">{text}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserUploadPrompt;
