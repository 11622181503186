import React from 'react';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import './ChatInputLoading.scss';

export const ChatInputLoading = () => {
  return (
    <div className="chat-input-loading">
      <div className="d-flex align-items-center py-1">
        <AIMascot
          faceSrc={happyFace}
          className="ai-mascot ai-mascot-small"
          isFloat={false}
        />
        <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
      </div>
      <div className="d-flex align-items-center gap-2 p-2 mt-1">
        <div
          className="spinner-grow spinner-grow-sm"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        <div
          className="spinner-grow spinner-grow-sm"
          role="status"
          style={{ animationDelay: '0.1s' }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        <div
          className="spinner-grow spinner-grow-sm"
          role="status"
          style={{ animationDelay: '0.2s' }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};
